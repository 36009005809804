import { PDFViewer } from "@react-pdf/renderer";
import axios from "axios";
import React from "react";
import MyDocument from "./MyDocument";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";

const Invoice = () => {
  const [invoiceData, setInvoiceData] = React.useState(null);

  React.useEffect(() => {
    document.title = "Sales Contract";
  }, []);

  const location = useLocation();
  let { id } = useParams();
  const getInfo = localStorage.getItem("dm-userInfo")
    ? JSON.parse(localStorage.getItem("dm-userInfo"))
    : null;

  React.useEffect(() => {
    if (id) {
      fetch(`https://cs-dm-api.apsl-bd.com/api/sales_contract/doc`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          p_id: id,
        }),
      }).then((response) => {
        response.json().then((result) => {
          setInvoiceData(result);
        });
      });
    }
  }, [id]);

  // console.log("invoiceData&&&&&&", invoiceData);

  return (
    <>
      {invoiceData && invoiceData.length ? (
        <PDFViewer
          style={{
            minHeight: "calc(100vh - 10px)",
            minWidth: "calc(100vw - 12px)",
          }}
          showToolbar={true}
        >
          <MyDocument invoiceData={invoiceData} getInfo={getInfo} />
        </PDFViewer>
      ) : (
        <div
          style={{
            minHeight: "80vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default Invoice;
