import { PDFViewer } from "@react-pdf/renderer";
import React from "react";
import MyDocument from "./MyDocument";
import MyDocument2 from "./MyDocument2";

import { useLocation, useParams } from "react-router-dom";

const Invoice = () => {
  const [invoiceData, setInvoiceData] = React.useState(null);
  const [invoiceData2, setInvoiceData2] = React.useState([]);

  React.useEffect(() => {
    document.title = "Sales Contract Commission";
  }, []);

  const location = useLocation();
  let { id } = useParams();
  const getInfo = localStorage.getItem("dm-userInfo")
    ? JSON.parse(localStorage.getItem("dm-userInfo"))
    : null;

  React.useEffect(() => {
    if (id) {
      fetch(`https://cs-dm-api.apsl-bd.com/api/sales_contract_amd/amd_list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          id: id,
        }),
      }).then((response) => {
        response.json().then((result) => {
          if (result.data && result.data.length) {
            const sortedResult = result.data.sort(
              (a, b) => b.amd_no - a.amd_no
            );

            console.log("sortedResult", sortedResult, id);

            for (let i = 0; i < sortedResult.length; i++) {
              fetch(`https://cs-dm-api.apsl-bd.com/api/sc_commission/doc`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
                body: JSON.stringify({
                  p_opmode: 5,
                  p_id: id,
                  p_amd_no: `${sortedResult[i].amd_no}`,
                }),
              }).then((response) => {
                response.json().then((result) => {
                  let addAmdNo = {
                    amd_no: `${sortedResult[i].amd_no}`,
                    amd_date: `${sortedResult[i].amd_date}`,
                    amd_by: `${sortedResult[i].created_by}`,
                  };

                  let currency = {
                    primary_currency: `${result?.[1]?.["0"]?.["primary_currency"]}`,
                    primary_currency_symbol:
                      result?.[1]?.["0"]?.["primary_currency_symbol"],
                    secondary_currency:
                      result?.[1]?.["0"]?.["secondary_currency"],
                    secondary_currency_symbol:
                      result?.[1]?.["0"]?.["secondary_currency_symbol"],
                  };

                  const valueRefine = result
                    ? Object.keys(result[0]).map((key) => {
                        return Object.assign(
                          result[0][key],
                          addAmdNo,
                          currency
                        );
                      })
                    : [];

                  let hash = Object.create(null);

                  let resultInfo =
                    valueRefine && valueRefine.length !== 0
                      ? valueRefine?.reduce(function (p, c) {
                          if (!hash[c.dm_order_no]) {
                            hash[c.dm_order_no] = {
                              dm_order_no: c.dm_order_no,
                              data: [],
                            };
                            p.push(hash[c.dm_order_no]);
                          }
                          hash[c.dm_order_no].data.push({
                            amd_no: c.amd_no ? c.amd_no : "",
                            amd_date: c.amd_date ? c.amd_date : "",
                            amd_by: c.amd_by ? c.amd_by : "",

                            primary_currency: c.primary_currency
                              ? c.primary_currency
                              : "",
                            primary_currency_symbol: c.primary_currency_symbol
                              ? c.primary_currency_symbol
                              : "",
                            secondary_currency: c.secondary_currency
                              ? c.secondary_currency
                              : "",
                            secondary_currency_symbol:
                              c.secondary_currency_symbol
                                ? c.secondary_currency_symbol
                                : "",
                            actual_comm: c.actual_comm ? c.actual_comm : "",
                            buyer_name: c.buyer_name ? c.buyer_name : "",
                            buyer_po: c.buyer_po ? c.buyer_po : "",
                            comm_per: c.comm_per ? c.comm_per : "",
                            comm_value: c.comm_value ? c.comm_value : "",
                            factory_fob: c.factory_fob ? c.factory_fob : "",
                            factory_value: c.factory_value
                              ? c.factory_value
                              : "",
                            oth_comm: c.oth_comm ? c.oth_comm : "",
                            ovr_comm: c.ovr_comm ? c.ovr_comm : "",
                            qty: c.qty ? c.qty : "",
                            rdl_fob: c.rdl_fob ? c.rdl_fob : "",
                            rdl_value: c.rdl_value ? c.rdl_value : "",
                            sales_contract_date: c.sales_contract_date
                              ? c.sales_contract_date
                              : "",
                            sales_contract_no: c.sales_contract_no
                              ? c.sales_contract_no
                              : "",
                            style: c.style ? c.style : "",
                          });

                          return p;
                        }, [])
                      : [];

                  // setInvoiceData2((currentArray) => [
                  //   ...currentArray,
                  //   valueRefine,
                  // ]);

                  setInvoiceData2((currentArray) => [
                    ...currentArray,
                    {
                      amd_no: sortedResult[i].amd_no,
                      amd_date: sortedResult[i].amd_date,
                      amd_by: sortedResult[i].created_by,
                      data: resultInfo,
                    },
                  ]);
                });
              });
            }
          } else {
            fetch(`https://cs-dm-api.apsl-bd.com/api/sc_commission/doc`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify({
                p_opmode: 4,
                p_id: id,
                p_amd_no: null,
              }),
            }).then((response) => {
              response.json().then((result) => {
                setInvoiceData(result);
              });
            });
          }
        });
      });
    } else {
      fetch(`https://cs-dm-api.apsl-bd.com/api/sc_commission/doc`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          p_opmode: 4,
          p_id: id,
          p_amd_no: null,
        }),
      }).then((response) => {
        response.json().then((result) => {
          setInvoiceData(result);
        });
      });
    }
  }, [id]);

  console.log("invoiceData2", invoiceData2);

  return (
    <PDFViewer
      style={{
        minHeight: "calc(100vh - 10px)",
        minWidth: "calc(100vw - 12px)",
      }}
      showToolbar={true}
    >
      {invoiceData2 && invoiceData2.length ? (
        <MyDocument2
          invoiceData={
            invoiceData2 && invoiceData2.length
              ? invoiceData2.sort((a, b) => b.amd_no - a.amd_no)
              : []
          }
          getInfo={getInfo}
        />
      ) : (
        <MyDocument
          invoiceData={invoiceData ? invoiceData : null}
          getInfo={getInfo}
        />
      )}
    </PDFViewer>
  );
};

export default Invoice;
