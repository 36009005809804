import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import logo from "./Renaissence@3x.jpeg";
import { grey } from "@material-ui/core/colors";
import { CurrencyToWords } from "currency-to-words";

// Create styles
const Quixote = ({ invoiceData, getInfo }) => {
  const currencyName = invoiceData && invoiceData.length ? invoiceData[2] : {};
  const tableHeaderData =
    invoiceData && invoiceData.length ? invoiceData[0] : {};

  const tableBodyData = invoiceData && invoiceData.length ? invoiceData[1] : {};

  const tableHeaderDataRefine = tableHeaderData
    ? Object.keys(tableHeaderData).map((key) => tableHeaderData[key])
    : [];

  const tableBodyDataRefine = tableBodyData
    ? Object.keys(tableBodyData).map((key) => tableBodyData[key])
    : [];

  let startPageNo = 0;
  let endPageNo = 0;

  const totalOrderQty =
    tableBodyDataRefine &&
    tableBodyDataRefine.reduce(
      (totalValue, currentValue) =>
        totalValue + Number(currentValue?.order_qty),
      0
    );
  // totalFobValue
  const totalFobValue =
    tableBodyDataRefine &&
    tableBodyDataRefine.reduce(
      (totalValue, currentValue) => totalValue + Number(currentValue?.t_amount),
      0
    );

  const totalValueInword = CurrencyToWords(
    totalFobValue ? totalFobValue.toFixed(2) : 0
  );

  const handlePDFLoadSuccess = ({ numPages }) => {
    console.log("TTTT", numPages);
  };

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd
  return (
    <Document onLoadSuccess={handlePDFLoadSuccess}>
      <Page orientation="portrait" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            marginBottom: "20px",
          }}
          fixed
        >
          {/* <Image
            style={{
              width: "700px",
              height: "75px",
              textAlign: "center",
              margin: "auto",
            }}
            src={logo}
          /> */}
          <Text
            style={{
              fontSize: 16,
              fontWeight: "bold",
              fontFamily: "Helvetica",
              borderBottom: "1px",
            }}
          >
            {tableHeaderDataRefine && tableHeaderDataRefine[0]?.company_name}
          </Text>
          <Text style={{ fontSize: 9, paddingTop: 3, paddingBottom: 4 }}>
            {tableHeaderDataRefine && tableHeaderDataRefine[0]?.company_address}
          </Text>
          <View
            style={{
              margin: "auto",
              height: "40px",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                fontSize: "12px",
                fontWeight: "bold",

                // backgroundColor: "rgb(56, 56, 141)",
                width: "150px",
                color: "black",
                padding: "6px",
                borderBottom: "1px",
              }}
            >
              PURCHASE CONTRACT
            </Text>
          </View>
        </View>

        <View
          style={{ display: "flex", flexDirection: "row", marginTop: "-30px" }}
          fixed
        >
          <View style={{ width: "70%" }}></View>
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              width: "30%",
              right: 0,
            }}
          >
            <View
              style={{
                width: "30%",

                fontWeight: 900,
              }}
            >
              <Text
                style={{
                  fontSize: 8,
                  padding: 2,
                  height: "auto",
                }}
              >
                REF NO.
              </Text>
              <Text style={{ fontSize: 8, padding: 2, height: "auto" }}>
                DATE
              </Text>
            </View>
            <View style={{ width: "70%" }}>
              <Text
                style={{
                  fontSize: 8,
                  padding: 2,
                  height: "auto",
                  fontWeight: 900,
                }}
              >
                :{" "}
                {tableHeaderDataRefine && tableHeaderDataRefine[0]?.dm_order_no}
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  padding: 2,
                  height: "auto",
                  fontWeight: 900,
                }}
              >
                :{" "}
                {tableHeaderDataRefine && tableHeaderDataRefine[0]?.order_date}
              </Text>
            </View>
          </View>
        </View>

        {/* Header Info */}

        <View
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ width: "50%", marginLeft: 20 }}>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",
                  }}
                >
                  BUYER
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.company_name}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                lineHeight: 1,
                marginTop: -2,
              }}
            >
              <View style={{ width: "50%", marginLeft: 20 }}></View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      marginLeft: 17,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.company_address}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 4,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ width: "50%", marginLeft: 20 }}>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",
                  }}
                >
                  BENEFICIARY
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.factory_name}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                lineHeight: 1,
                marginTop: -2,
              }}
            >
              <View style={{ width: "50%", marginLeft: 20 }}></View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      marginLeft: 17,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.factory_address}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 4,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ width: "50%", marginLeft: 20 }}>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",
                  }}
                >
                  NOTIFY PARTIES
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.buyer_name}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                lineHeight: 1,
                marginTop: -2,
              }}
            >
              <View style={{ width: "50%", marginLeft: 20 }}></View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      marginLeft: 17,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.buyer_address}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 4,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ width: "50%", marginLeft: 20 }}>
                {/* <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",
                    
                  }}
                >
                  NOTIFY PARTIES
                </Text> */}
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  {/* <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text> */}
                  <Text
                    style={{
                      marginLeft: 17,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.company_name}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                lineHeight: 1,
                marginTop: -2,
              }}
            >
              <View style={{ width: "50%", marginLeft: 20 }}></View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      marginLeft: 17,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.company_address}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 4,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ width: "50%", marginLeft: 20 }}>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",
                  }}
                >
                  DESCRIPTION OF GOODS
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    AS PER PAGE- 2-{endPageNo}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 4,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ width: "50%", marginLeft: 20 }}>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",
                  }}
                >
                  QUANTITY
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 100,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.order_qty}{" "}
                    PCS
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 4,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ width: "50%", marginLeft: 20 }}>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",
                  }}
                >
                  UNIT PRICE
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    AS PER PAGE- 2-{endPageNo}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 4,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ width: "50%", marginLeft: 20 }}>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",
                  }}
                >
                  TOTAL AMOUNT
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    $
                  </Text>
                  <Text
                    style={{
                      marginLeft: 80,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.t_amount}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 4,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ width: "50%", marginLeft: 20 }}>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",
                  }}
                >
                  TERMS OF PAYMENT
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.lead_time}{" "}
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.term_details}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 4,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View
                style={{ width: "50%", marginLeft: 20, flexDirection: "row" }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                >
                  BENEFICIARY BANK DETAILS
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                >
                  ACCOUNT NAME
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.f_acname}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: -2,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View
                style={{ width: "50%", marginLeft: 20, flexDirection: "row" }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                >
                  ACCOUNT NUMBER
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine && tableHeaderDataRefine[0]?.f_acno}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: -2,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View
                style={{ width: "50%", marginLeft: 20, flexDirection: "row" }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                >
                  BANK
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine && tableHeaderDataRefine[0]?.f_bank}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: -2,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View
                style={{ width: "50%", marginLeft: 20, flexDirection: "row" }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                >
                  BRANCH
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.f_branch_name}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: -2,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View
                style={{ width: "50%", marginLeft: 20, flexDirection: "row" }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                >
                  ADDRESS
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.f_bakaddress}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: -2,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View
                style={{ width: "50%", marginLeft: 20, flexDirection: "row" }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                >
                  SWIFT NO
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.f_swift_code}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 4,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View
                style={{ width: "50%", marginLeft: 20, flexDirection: "row" }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                >
                  TT ADVISING BANK
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                >
                  ACCOUNT NAME
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.dm_acname}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {/* <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: -2,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View
                style={{ width: "50%", marginLeft: 20, flexDirection: "row" }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                >
                  ACCOUNT NUMBER
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.buyer_bakac_no}
                  </Text>
                </View>
              </View>
            </View>
          </View> */}
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: -2,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View
                style={{ width: "50%", marginLeft: 20, flexDirection: "row" }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                >
                  BANK
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine && tableHeaderDataRefine[0]?.dm_bank}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: -2,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View
                style={{ width: "50%", marginLeft: 20, flexDirection: "row" }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                >
                  ADDRESS
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.dm_bakaddress}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: -2,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View
                style={{ width: "50%", marginLeft: 20, flexDirection: "row" }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                ></Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",

                    width: "50%",
                  }}
                >
                  SWIFT NO
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.dm_swift_code}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 4,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ width: "50%", marginLeft: 20 }}>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",
                  }}
                >
                  DOCS TO BE SEND
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.company_name}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                lineHeight: 1,
                marginTop: -2,
              }}
            >
              <View style={{ width: "50%", marginLeft: 20 }}></View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      marginLeft: 17,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.company_address}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 4,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ width: "50%", marginLeft: 20 }}>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",
                  }}
                >
                  PORT OF LOADING
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    FOB, CHITTAGONG SEA/DHAKA AIR , BANGLADESH
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 4,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ width: "50%", marginLeft: 20 }}>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",
                  }}
                >
                  PORT OF DESTINATION
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    ANY SEA/AIR PORT,{" "}
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.port_name}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 4,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ width: "50%", marginLeft: 20 }}>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",
                  }}
                >
                  DATE OF SHIPMENT
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    AS PER PAGE -2-{endPageNo}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 4,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ width: "50%", marginLeft: 20 }}>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",
                  }}
                >
                  DATE & PLACE OF EXPIRY
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    15 DAYS FROM THE DELIVERY DATE,{" "}
                    {tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.company_country}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 4,
            }}
          >
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ width: "50%", marginLeft: 20 }}>
                <Text
                  style={{
                    fontSize: 8,
                    padding: 2,
                    height: "auto",
                    fontWeight: "bold",
                  }}
                >
                  DOCUMENTS REQUIRED
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "50%",
                  right: 0,
                }}
              >
                <View
                  style={{
                    fontWeight: 900,
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    :
                  </Text>
                  <Text
                    style={{
                      marginLeft: 10,
                      fontSize: 8,
                      padding: 2,
                      height: "auto",
                    }}
                  >
                    {`1) COMMERCIAL INVOICE, 2) PACKING LIST`}
                    {"\n"}{" "}
                    {`3) FULL SET ON BOARD OCEAN BL MADE OUT ORDER ANY NEGOTIATING BANK BANGLADESH AND ENDORSED TO ${
                      tableHeaderDataRefine &&
                      tableHeaderDataRefine[0]?.company_name
                    }. 4) REX NUMBER.`}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 4,
            }}
          >
            <Text
              style={{
                fontSize: 8,
                height: "auto",
                fontWeight: "bold",
              }}
            >
              "WE DO HEREBY CONFIRM THAT, WE HAVE PLACED MENTIONED ORDER"
            </Text>
          </View>

          <View
            wrap={false}
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: 30,
            }}
          >
            <View>
              <View style={{ textAlign: "center" }}>
                <Text
                  style={{
                    backgroundColor: "black",
                    height: "1px",
                    width: "120px",
                  }}
                ></Text>
                <Text style={{ fontSize: "8px", width: "120px" }}>
                  PROPOSED BY
                </Text>
                <Text style={{ fontSize: "8px", width: "120px" }}>
                  AUTHORISED SIGNATURE
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    width: "120px",
                    fontWeight: "bold",
                  }}
                >
                  {tableHeaderDataRefine &&
                    tableHeaderDataRefine[0]?.company_name}
                </Text>
              </View>
            </View>
            <View>
              <View style={{ textAlign: "center" }}>
                {/* <Text
                  style={{
                    backgroundColor: "black",
                    height: "1px",
                    width: "120px",
                  }}
                ></Text> */}
                <Text style={{ fontSize: "9px", width: "120px" }}></Text>
              </View>
            </View>
            <View>
              <View style={{ textAlign: "center" }}>
                <Text
                  style={{
                    backgroundColor: "black",
                    height: "1px",
                    width: "120px",
                  }}
                ></Text>
                <Text style={{ fontSize: "8px", width: "120px" }}>
                  ACCEPTED BY
                </Text>
                <Text style={{ fontSize: "8px", width: "120px" }}>
                  AUTHORISED SIGNATURE
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    width: "120px",
                    fontWeight: "bold",
                  }}
                >
                  {tableHeaderDataRefine &&
                    tableHeaderDataRefine[0]?.factory_name}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* TABLE PART */}
        <View style={{ marginTop: "20px" }} break wrap={false}>
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              width: "100%",
              backgroundColor: "#d3cfcf",
            }}
            fixed
          >
            <Text
              style={{
                padding: "4px 3px",
                width: "10%",
                fontSize: "8px",

                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              BRAND
            </Text>

            <Text
              style={{
                padding: "4px 3px",
                width: "10%",
                fontSize: "8px",

                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              STYLE NO
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "8px",
                width: "25%",

                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              DESCRIPTION
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                width: "5%",

                fontWeight: "bold",
                borderRight: "1px solid black",
                fontSize: "8px",
                textAlign: "center",
              }}
            ></Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "8px",
                width: "25%",

                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              FABRIC
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "8px",
                width: "10%",

                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              QTY
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "8px",
                width: "10%",

                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              UNIT PRICE
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "8px",
                width: "10%",

                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              AMOUNT
            </Text>
            <Text
              style={{
                padding: "4px 3px",
                fontSize: "8px",
                width: "10%",

                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              SHIP DT
            </Text>
          </View>
          <View style={{ borderTop: "1px solid black" }}>
            {tableBodyDataRefine &&
              tableBodyDataRefine.length &&
              tableBodyDataRefine.map((item, index) => (
                <View
                  key={index}
                  wrap={false}
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  <Text
                    style={{
                      padding: "2px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.brand}
                  </Text>
                  <Text
                    style={{
                      padding: "2px",
                      fontSize: "7px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.style}
                  </Text>
                  <Text
                    style={{
                      padding: "2px",
                      fontSize: "7px",
                      height: "auto",
                      width: "25%",
                      borderRight: "1px solid black",
                      textAlign: "left",
                    }}
                  >
                    {item?.product_name}
                  </Text>
                  <Text
                    style={{
                      padding: "2px",
                      fontSize: "7px",
                      height: "auto",
                      width: "5%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {/* {item?.GG} */}
                  </Text>
                  <Text
                    style={{
                      padding: "2px",
                      fontSize: "7px",
                      height: "auto",
                      width: "25%",
                      borderRight: "1px solid black",
                      textAlign: "left",
                    }}
                  >
                    {item?.yarn_fabric}
                  </Text>
                  <Text
                    style={{
                      padding: "2px",
                      fontSize: "7px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "right",
                    }}
                  >
                    {item?.order_qty} PCS
                  </Text>
                  <Text
                    style={{
                      padding: "2px",
                      fontSize: "7px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    $ {item?.unit_price} /PC
                  </Text>
                  <Text
                    style={{
                      padding: "2px",
                      fontSize: "7px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "right",
                    }}
                  >
                    $ {item?.t_amount}
                  </Text>
                  <Text
                    style={{
                      padding: "2px",
                      fontSize: "7px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.ship_date}
                  </Text>
                </View>
              ))}
            <View
              wrap={false}
              style={{
                flexDirection: "row",
                width: "100%",
                border: "1px solid black",
              }}
            >
              <Text
                style={{
                  padding: "2px",
                  fontSize: "8px",
                  height: "auto",

                  fontWeight: "bold",
                  width: "75%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                GRAND TOTAL
              </Text>

              <Text
                style={{
                  padding: "2px",
                  fontSize: "8px",
                  height: "auto",

                  fontWeight: "bold",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {totalOrderQty} PCS
              </Text>
              <Text
                style={{
                  padding: "2px",
                  fontSize: "8px",
                  height: "auto",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              ></Text>

              <Text
                style={{
                  padding: "2px",
                  fontSize: "8px",
                  height: "auto",
                  width: "10%",

                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                $ {Number(totalFobValue).toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "2px",
                  fontSize: "8px",
                  height: "auto",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              ></Text>
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            borderTop: "1px solid black",
          }}
        >
          <Text
            style={{
              padding: "5px",
              fontSize: "10px",
              fontWeight: "bold",
              height: "auto",
              width: "100%",
              paddingLeft: 5,
            }}
          >
            SAY :{" "}
            <Text style={{ fontSize: "9px", fontWeight: "bold" }}>
              UNITED STATES DOLLARES{" "}
              {totalValueInword?.replace(/dollars/g, "").toUpperCase()} ONLY.
            </Text>
          </Text>
        </View>

        {/* Signature part */}
        {/* <View
          wrap={false}
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "60px",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Prepared By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Checked By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Approved By
              </Text>
            </View>
          </View>
        </View> */}

        <View
          wrap={false}
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "30px",
            bottom: 0,
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "8px", width: "120px" }}>
                PROPOSED BY
              </Text>
              <Text style={{ fontSize: "8px", width: "120px" }}>
                AUTHORISED SIGNATURE
              </Text>
              <Text
                style={{
                  fontSize: "8px",
                  width: "120px",
                  fontWeight: "bold",
                }}
              >
                {tableHeaderDataRefine &&
                  tableHeaderDataRefine[0]?.company_name}
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              {/* <Text
                  style={{
                    backgroundColor: "black",
                    height: "1px",
                    width: "120px",
                  }}
                ></Text> */}
              <Text style={{ fontSize: "9px", width: "120px" }}></Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "8px", width: "120px" }}>
                ACCEPTED BY
              </Text>
              <Text style={{ fontSize: "8px", width: "120px" }}>
                AUTHORISED SIGNATURE
              </Text>
              <Text
                style={{
                  fontSize: "8px",
                  width: "120px",
                  fontWeight: "bold",
                }}
              >
                {tableHeaderDataRefine &&
                  tableHeaderDataRefine[0]?.factory_name}
              </Text>
            </View>
          </View>
        </View>

        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => {
            endPageNo = totalPages;
            return `${pageNumber} of ${totalPages}`;
          }}
          fixed
        />
      </Page>
    </Document>
  );
};

Font.register({
  family: "Helvetica",
  fonts: [
    {
      src: "/assets/fonts/Helvetica.ttf",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/Helvetica Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Times",
  fonts: [
    {
      src: "/assets/fonts/Times-Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
    // marginTop: "-20px",
  },
  body: {
    paddingTop: 20,
    paddingBottom: 60,
    paddingHorizontal: 20,
    fontFamily: "Helvetica",
    color: "black",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    // fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    // fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
